import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Input, DatePicker, Button } from "antd-min";
import moment from "moment";
import { withRouter, GLUtil, PaginationParams, GLGlobal, GLForm, GLFormComponentProps, FormHelper, alignPop, LanguageDateFormat } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { MainTitle, OrderStatusRadios, ListStatesStore, OrderListStatesAllValue, OrderListStatesSubmittedValue, Container } from "@app/components";
import { GLGridSorter, GLGrid, GLGridColumnProps } from "@app/components/gl-grid";
import { MaterialOrdersModel, MaterialOrdersPropsModel } from "@app/service/material";
import { StateType } from "@app/states";
import { getRegionOrders } from "@app/states/material";
import { DateHelper, MaterialHelper, fmtMsg, GSAdminAction, GSSchoolAction, formatToMaterialRequestId, sliceMoment } from "@app/util";
import "./order.less";
import { MergedRowsStruct } from "@app/components/gl-grid/mergemanger";
import { AllowDragging } from "wijmo/wijmo.grid";
import { getUnitTextForLocalProduct } from "@app/page/product/components/unitprice";

const OrderMergedColumns: Map<string, number> = new Map([
    [MaterialOrdersPropsModel.schoolName, 0],
    [MaterialOrdersPropsModel.campusName, 1],
    [MaterialOrdersPropsModel.id, 2],
    [MaterialOrdersPropsModel.submissionDate, 3],
    [MaterialOrdersPropsModel.status, 4],
]);
const mergedRowsStruct: MergedRowsStruct[] = [
    {
        mergedRowsByKeys: [MaterialOrdersPropsModel.id],
        mergedRowsByColumns: [
            MaterialOrdersPropsModel.schoolName,
            MaterialOrdersPropsModel.campusName,
            MaterialOrdersPropsModel.id,
            MaterialOrdersPropsModel.submissionDate,
            MaterialOrdersPropsModel.status,
        ],
    },
    {
        mergedRowsByKeys: [MaterialOrdersPropsModel.id, MaterialOrdersPropsModel.schoolClassId],
        mergedRowsByColumns: [MaterialOrdersPropsModel.schoolClassName],
    },
];
interface RegionOrdersProps extends RouteComponentProps<any> {
    loading?: boolean;
    statusCounts: any;
    totalCount: number;
    orders?: MaterialOrdersModel[];
    getRegionOrders: (query) => void;
}

const keywordFieldName = "keyword";
const submissionDateFieldName = "submissionDate";

@withRouter
@GLForm.create()
@connect(({ material: { totalCount, statusCounts, orders } }: StateType) => ({ totalCount, statusCounts, orders }), { getRegionOrders })
export class RegionOrdersPage extends Component<RegionOrdersProps & RouteComponentProps<any> & GLFormComponentProps, any> {
    private listStateStore: ListStatesStore;
    private sorter: GLGridSorter;
    private pagination: PaginationParams = new PaginationParams(1, 20);
    private columnSortingMap: Map<string, string> = new Map<string, string>([
        [MaterialOrdersPropsModel.id, "materialRequestId"],
        [MaterialOrdersPropsModel.status, "status"],
    ]);

    constructor(props, context) {
        super(props, context);
        this.listStateStore = new ListStatesStore("region-order-list-states", "region-order-list-states");
        this.sorter = {
            columnName: MaterialOrdersPropsModel.submissionDate,
            columnSortName: MaterialOrdersPropsModel.submissionDate,
            ascending: false,
        };
        this.listStateStore.setState(OrderListStatesSubmittedValue);
        this.state = {
            listState: this.listStateStore.getState(),
        };
        this.onQuery = this.onQuery.bind(this);
        this.onColumnSorting = this.onColumnSorting.bind(this);
        this.onPagingChange = this.onPagingChange.bind(this);
    }

    getColumns(): GLGridColumnProps<MaterialOrdersModel>[] {
        let columns: GLGridColumnProps<MaterialOrdersModel>[] = [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: MaterialOrdersPropsModel.schoolName,
                width: "15%",
                className: "navigation-col order-col",
                render: (text, order, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Schools, {
                            regionId: order.regionId,
                            schoolId: order.schoolId,
                        })}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: MaterialOrdersPropsModel.campusName,
                width: "15%",
                className: "navigation-col order-col",
                render: (text, order, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Classes, {
                            regionId: order.regionId,
                            schoolId: order.schoolId,
                            campusId: order.campusId,
                        })}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
            },
            {
                title: SchoolLocale.MaterialOrdersItemFieldOrderDate,
                dataIndex: MaterialOrdersPropsModel.materialRequestIdText,
                width: "15%",
                className: "navigation-col order-col",
                render: (text, order, index) => {
                    const { regionId, schoolId, campusId } = order;
                    if (GLGlobal.isActionValid(GSSchoolAction.CampusOrders)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.CampusOrdersItem, {
                            regionId,
                            schoolId,
                            campusId,
                            orderId: order.id,
                        })}?from=region" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
            },
            {
                title: SchoolLocale.MaterialOrderPagerFieldSubmissionDate,
                dataIndex: MaterialOrdersPropsModel.submissionDate,
                width: "15%",
                className: "order-col",
                render: (text, record, index) => {
                    return DateHelper.formatDate2Local(record.submissionDate);
                },
            },
            {
                title: SchoolLocale.MaterialOrdersItemFieldStatus,
                dataIndex: MaterialOrdersPropsModel.statusText,
                width: "10%",
                className: "order-col",
            },
            {
                title: SchoolLocale.MaterialPurchaseItemFieldClass,
                dataIndex: MaterialOrdersPropsModel.schoolClassName,
                sorter: false,
                width: "17%",
                className: "order-col",
            },
            {
                title: SchoolLocale.MaterialPurchaseItemFieldProduct,
                dataIndex: MaterialOrdersPropsModel.productName,
                sorter: false,
                width: "17%",
                className: "order-col",
            },
            {
                title: SchoolLocale.MaterialPurchaseItemFieldUnit,
                dataIndex: MaterialOrdersPropsModel.productUnitText,
                sorter: false,
                align: "left",
                width: "5%",
                className: "order-col",
            },
            {
                title: SchoolLocale.MaterialPurchaseItemFieldQuantity,
                dataIndex: MaterialOrdersPropsModel.quantity,
                sorter: false,
                align: "left",
                width: "8%",
                className: "order-col",
                render: (text, record, index) => {
                    return record.productName ? (record.quantity ? record.quantity : 0) : "";
                },
            },
        ];
        columns.forEach((column) => {
            if (![MaterialOrdersPropsModel.schoolName, MaterialOrdersPropsModel.campusName].includes(column.dataIndex as MaterialOrdersPropsModel)) {
                column.allowDragging = false;
            }
        });
        return columns;
    }

    formatData(data) {
        return data.map((order) => ({
            ...order,
            materialRequestIdText: formatToMaterialRequestId(order.materialRequestId),
            statusText: fmtMsg({ id: MaterialHelper.MaterialOrderStatus[order.status] }),
            productUnitText: order.productUnit ? order.productUnit > 0 ? getUnitTextForLocalProduct(order.productUnit, false) : getUnitTextForLocalProduct(Math.abs(order.productUnit), true) : '',
        }))
    }

    componentDidMount() {
        this.getRegionOrders(this.sorter);
    }

    getRegionOrders(sorter: GLGridSorter) {
        const state = this.listStateStore.getState();
        const orderStatus = state == OrderListStatesAllValue ? null : state;
        const { getFieldsValue } = this.props.form;
        const { keyword, submissionDate } = getFieldsValue([keywordFieldName, submissionDateFieldName]);
        this.props.getRegionOrders({
            query: {
                ...this.listStateStore.getUrlQuery(this.pagination.toRequest()),
                filterText: keyword,
                submissionDate: submissionDate && DateHelper.formatDate2ISO(sliceMoment(moment(submissionDate))),
                sortBy: sorter ? sorter.columnSortName : null,
                isDescending: sorter && sorter.columnSortName ? !sorter.ascending : null,
                status: orderStatus,
            },
        });
    }

    onQuery(e) {
        e.preventDefault();
        this.pagination.current = 1;
        this.getRegionOrders(this.sorter);
    }

    onColumnSorting(sorter: GLGridSorter) {
        const columnName = this.columnSortingMap.has(sorter.columnSortName)
            ? this.columnSortingMap.get(sorter.columnSortName)
            : sorter.columnSortName;
        this.sorter = { ...sorter, columnSortName: columnName };
        this.getRegionOrders(this.sorter);
    }

    onPagingChange(pagination) {
        this.pagination.current = pagination.current;
        this.getRegionOrders(this.sorter);
    }

    render() {
        const { statusCounts, totalCount, orders, form } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const extendForm = { ...form, colon: true };
        return (
            <Container fullWidth className="region-order-list">
                <MainTitle title={GSAdminLocale.RegionOrderTitle} />
                <div className="region-order-filter">
                    <GLForm layout="inline" form={form} onSubmit={this.onQuery}>
                        {renderFormItem(
                            extendForm,
                            GSAdminLocale.RegionOrderSearchKeyword,
                            keywordFieldName,
                            <Input placeholder={fmtMsg({ id: GSAdminLocale.RegionOrderSearchPlaceHolder })} />
                        )}
                        {renderFormItem(
                            extendForm,
                            GSAdminLocale.RegionOrderSearchDateSubmission,
                            submissionDateFieldName,
                            <DatePicker {...alignPop({ type: "DatePicker" })} format={LanguageDateFormat[GLGlobal.intl.locale]} />
                        )}
                        <Button className="region-order-filter-submit" type="primary" htmlType="submit">
                            {fmtMsg(SchoolLocale.InvitationSchoolInvitationHistorySearch)}
                        </Button>
                    </GLForm>
                </div>
                <div className="region-order-list-states">
                    <OrderStatusRadios
                        statusCounts={statusCounts}
                        value={this.state.listState}
                        onChange={(state) => {
                            this.setState({ listState: state });
                            this.listStateStore.setState(state);
                            this.pagination.current = 1;
                            this.onPagingChange(new PaginationParams(1, 20));
                        }}
                    />
                </div>
                <GLGrid
                    allowDragging={AllowDragging.Columns}
                    // allowGrouping={true}
                    columns={this.getColumns()}
                    dataSource={this.formatData(orders)}
                    defaultSorter={this.sorter}
                    // mergeDataKey={MaterialOrdersPropsModel.id}
                    // mergingColumns={OrderMergedColumns}
                    mergedRowsStruct={mergedRowsStruct}
                    onColumnSorting={this.onColumnSorting}
                    pagination={{ ...this.pagination, total: totalCount }}
                    onChange={this.onPagingChange}
                    loading={false}
                />
            </Container>
        );
    }
}
